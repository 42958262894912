// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { yieldCallback } from '@zillow/yield-callback';
import 'app/shared/modules/frontpage/TopCities.scss';
import styled from 'styled-components';
import { inset, padding, stacked } from 'app/shared/styles/_spacing';
import { textOverflow } from 'app/shared/styles/_mixins';
import Linker from 'app/shared/modules/Linker';
import Container from 'app/shared/core/Container';
import Section from 'app/shared/modules/Section';
import Center from 'app/shared/modules/Center';
import Title from 'app/shared/modules/Title';
import { connect } from 'react-redux';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { analyticsEvent } from 'app/client/universal-analytics';

const StyledTitle = styled(Title)`
  ${padding.top._3x};
  ${stacked._3x};
`;
const StyledInsetListItem = styled.li`
  ${inset._xs};
  ${textOverflow};
`;
const TOP_CITIES = [
  {
    title: 'Atlanta apartments for rent',
    url: '/atlanta-ga/apartments-for-rent',
  },
  {
    title: 'Austin apartments for rent',
    url: '/austin-tx/apartments-for-rent',
  },
  {
    title: 'Baltimore apartments for rent',
    url: '/baltimore-md/apartments-for-rent',
  },
  {
    title: 'Boston apartments for rent',
    url: '/boston-ma/apartments-for-rent',
  },
  {
    title: 'Bronx NYC apartments for rent',
    url: '/bronx-new-york-ny/apartments-for-rent',
  },
  {
    title: 'Brooklyn NYC apartments for rent',
    url: '/brooklyn-new-york-ny/apartments-for-rent',
  },
  {
    title: 'Charlotte apartments for rent',
    url: '/charlotte-nc/apartments-for-rent',
  },
  {
    title: 'Chicago apartments for rent',
    url: '/chicago-il/apartments-for-rent',
  },
  {
    title: 'Dallas apartments for rent',
    url: '/dallas-tx/apartments-for-rent',
  },
  {
    title: 'Denver apartments for rent',
    url: '/denver-co/apartments-for-rent',
  },
  {
    title: 'Houston apartments for rent',
    url: '/houston-tx/apartments-for-rent',
  },
  {
    title: 'Jersey City apartments for rent',
    url: '/jersey-city-nj/apartments-for-rent',
  },
  {
    title: 'Long Beach apartments for rent',
    url: '/long-beach-ca/apartments-for-rent',
  },
  {
    title: 'Manhattan NYC apartments for rent',
    url: '/manhattan-new-york-ny/apartments-for-rent',
  },
  {
    title: 'Miami apartments for rent',
    url: '/miami-fl/apartments-for-rent',
  },
  {
    title: 'Minneapolis apartments for rent',
    url: '/minneapolis-mn/apartments-for-rent',
  },
  {
    title: 'New York City apartments for rent',
    url: '/new-york-ny/apartments-for-rent',
  },
  {
    title: 'Oakland apartments for rent',
    url: '/oakland-ca/apartments-for-rent',
  },
  {
    title: 'Oklahoma City apartments for rent',
    url: '/oklahoma-city-ok/apartments-for-rent',
  },
  {
    title: 'Philadelphia apartments for rent',
    url: '/philadelphia-pa/apartments-for-rent',
  },
  {
    title: 'Queens NYC apartments for rent',
    url: '/queens-new-york-ny/apartments-for-rent',
  },
  {
    title: 'Sacramento apartments for rent',
    url: '/sacramento-ca/apartments-for-rent',
  },
  {
    title: 'San Francisco apartments for rent',
    url: '/san-francisco-ca/apartments-for-rent',
  },
  {
    title: 'Seattle apartments for rent',
    url: '/seattle-wa/apartments-for-rent',
  },
  {
    title: 'Washington DC apartments for rent',
    url: '/washington-dc/apartments-for-rent',
  },
  {
    title: 'Columbus apartments for rent',
    url: '/columbus-oh/apartments-for-rent',
  },
  {
    title: 'Fort Worth apartments for rent',
    url: '/fort-worth-tx/apartments-for-rent',
  },
  {
    title: 'Fresno apartments for rent',
    url: '/fresno-ca/apartments-for-rent',
  },
  {
    title: 'Indianapolis apartments for rent',
    url: '/indianapolis-in/apartments-for-rent',
  },
  {
    title: 'Jacksonville apartments for rent',
    url: '/jacksonville-fl/apartments-for-rent',
  },
  {
    title: 'Las Vegas apartments for rent',
    url: '/las-vegas-nv/apartments-for-rent',
  },
  {
    title: 'Memphis apartments for rent',
    url: '/memphis-tn/apartments-for-rent',
  },
  {
    title: 'Milwaukee apartments for rent',
    url: '/milwaukee-wi/apartments-for-rent',
  },
  {
    title: 'Nashville apartments for rent',
    url: '/nashville-tn/apartments-for-rent',
  },
  {
    title: 'Phoenix apartments for rent',
    url: '/phoenix-az/apartments-for-rent',
  },
  {
    title: 'Portland apartments for rent',
    url: '/portland-or/apartments-for-rent',
  },
  {
    title: 'San Antonio apartments for rent',
    url: '/san-antonio-tx/apartments-for-rent',
  },
  {
    title: 'San Jose apartments for rent',
    url: '/san-jose-ca/apartments-for-rent',
  },
  {
    title: 'Tampa apartments for rent',
    url: '/tampa-fl/apartments-for-rent',
  },
  {
    title: 'Tuscon apartments for rent',
    url: '/tuscon-az/apartments-for-rent',
  },
];
class TopCities extends React.Component {
  yieldTrackAndTransitionTo = yieldCallback((path) => {
    this.props.dispatch(analyticsEvent(gaEvents.AREAPAGE_TOP_CITIES_CLICK));
    window.router.transitionTo(path);
  });
  handleTopCityClick = (e, path) => {
    // Prevent the browser from going through with its default <a> behavior
    e.preventDefault();

    this.yieldTrackAndTransitionTo(path);
  };

  render() {
    return (
      <Container className="TopCities">
        <Section>
          <Center>
            <StyledTitle size="lg" htmlTag="h2">
              Top cities
            </StyledTitle>
            <ul className="TopCities-group">
              {TOP_CITIES.map((city) => (
                <StyledInsetListItem className="TopCities-city-item TopCities-city-text" key={city.title}>
                  <Linker onClick={(e) => this.handleTopCityClick(e, city.url)} linkType="accent" to={city.url}>
                    {city.title}
                  </Linker>
                </StyledInsetListItem>
              ))}
            </ul>
          </Center>
        </Section>
      </Container>
    );
  }
}

export default connect()(TopCities);
